<template>
  <div id="external" :class="[options.controllerBackground ? 'hasBackground' : 'hasNotBackground', {'scrollDisabled' : scrollDisabled}]">
    <!-- HOW TO PLAY -->
    <section class="section p-0" v-if="serverState < 2 && options.queue.enabled">
      <div class="waiting is-flex is-flex-direction-column has-background-white-ter has-text-black-bis">
        <div class="top p-5">
          <div class="mb-5 has-text-weight-bold">{{ $t('exp_external.howToPlay') }}</div>
          <div v-if="options.tutorial.animation" class="tutorial p-5 is-flex is-justify-content-center">
            <img :src="options.tutorial.animation.url" alt="How to play" class="image" />
          </div>
          <p v-if="options.tutorial.text" class="mt-2">{{ options.tutorial.text }}</p>
        </div>
        <div v-if="moreOnePlayer" class="bottom is-flex is-align-items-center is-justify-content-center">
          <span class="is-size-5">{{ $t('exp_external.waitingPlayers') }}</span>
        </div>
      </div>
    </section>
    <!-- GAME STARTED -->
    <section class="section playing has-background-white-ter controllerContainer p-0"
      :class="justifyContentClass"
      v-if="serverState == 2 || (!options.queue.enabled && serverState != 3)"
      :style="{ backgroundImage: bgControllerUrl }">

      <!-- LAYOUT BUILDER -->
      <template v-for="block in options.layout">
        <component
          :is="block.blockType" 
          :block="block"
          :key="block.id"
        />
      </template>

     

    </section>

    <!-- GAME FINISHED -->
    <section v-if="(serverState == 3)" :style="{ backgroundImage: bgControllerUrl }" class="match-finished section p-5">
      <!-- Scoreboard -->
      <div class="top-section">
        <template v-if="!hasQueue" >
        
          <img v-if="options.finalScreen.finalImage"
            :src="encodeURI(options.finalScreen.finalImage.url)"
            class="final-image mb-2"/>
          <div v-if="options.finalScreen.finalText"
            class="py-5 is-size-3 has-text-weight-bold">{{ options.finalScreen.finalText }}
          </div>
          <div v-if="options.finalScreen.finalTextSubtitle"
            class="is-size-6 has-text-centered">{{ options.finalScreen.finalTextSubtitle }}
          </div>
        </template>
        <template v-else-if="result.win">
          <div class="is-size-2">
            <!-- check si gano -->
            <svg class="win" viewBox="0 0 512 512">
              <path
                d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
            {{ $t('exp_external.youWin') }}
          </div>
          <div v-if="options.finalScreen.winText" class="is-size-5">{{ options.finalScreen.winText }}</div>
        </template>
        <template v-else-if="result.win != undefined">
          <div class="is-size-2">
            <!-- cruz si perdio -->
            <svg class="lose" viewBox="0 0 320 512">
              <path
                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
            {{ $t('exp_external.youLost') }}
          </div>
          <div v-if="options.finalScreen.lostText" class="is-size-5">{{ options.finalScreen.lostText }}</div>
        </template>
        <template v-else>
          <div class="is-size-2">
            <svg class="tied" viewBox="0 0 320 512">
              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
            {{ $t('exp_external.youTied') }}
          </div>
          <div v-if="options.finalScreen.tiedText" class="is-size-5">{{ options.finalScreen.tiedText }}</div>
        </template>

      </div>
      <div class="backToMenuWrapper auto-height">
        <b-button type="is-primary" :label="$t('system.backToMenu')" expanded @click="$router.push('/')" />
      </div>
    </section>
  </div>
</template>
    
<script>
export default {
  // Documentacion modelo y envio datos por @eypacha
  // https://docs.google.com/document/d/1lrJ8vTiNArbwq0pHMZHw_ZK9xPzEAXRmTq9B5mijKBA/edit

  components: {
    ExternalText: () => import('@/components/controllers/ExternalLayout/ExternalText.vue'),
    externalImage: () => import('@/components/controllers/ExternalLayout/ExternalImage.vue'),
    ExternalButtons: () => import('@/components/controllers/ExternalLayout/ExternalButtonGroup.vue'),
    ExternalForm: () => import('@/components/controllers/ExternalLayout/ExternalForm.vue'),
    ExternalGyro: () => import('@/components/controllers/ExternalLayout/ExternalGyro.vue'),
    ExternalJoystick: () => import('@/components/controllers/ExternalLayout/ExternalJoystick.vue'),
    ExternalNumberSlider: () => import('@/components/controllers/ExternalLayout/ExternalNumberSlider.vue'),
    ExternalTouchpad: () => import('@/components/controllers/ExternalLayout/ExternalTouchpad.vue'),
    ExternalLeftRight: () => import('@/components/controllers/ExternalLayout/ExternalLeftRight.vue'),
    externalInputField: () => import('@/components/controllers/ExternalLayout/ExternalInputField.vue'),
    externalInputImageUpload: () => import('@/components/controllers/ExternalLayout/ExternalInputImageUpload.vue'),
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      result: {
        win: false,
        gameTime: ''
      },
      leftRight: {
        lastSent: null
      },
      scrollDisabled: false,
    }
  },

  computed: {
    justifyContentClass() {
      return `is-justify-content-${this.options.layoutAlignment}`
    },
    hasQueue() {
      return this.$parent.room.experience.component[0].queue.enabled
    },
    serverState() {
      return this.$parent.serverState
    },
    moreOnePlayer() {
      return this.$parent.room.experience.component[0].queue.maxPlayers > 1
    },
    bgControllerUrl() {
      return this.options.controllerBackground ?
        'url(' + encodeURI(
          this.options.controllerBackground.sizes.mobile.url ?
            this.options.controllerBackground.sizes.mobile.url :
            this.options.controllerBackground.url) + ')'
        : ''
    },
    ShowDebugger() {
      return this.options.debugControllers;
    },
  },
  methods: {
  },
  mounted() {
  },
  watch: {

  },
  sockets: {
    showResult(data) {

      if(this.hasQueue){
        this.result.win = data.winner;
        let minutes = Math.floor(data.gameTime / 60)
        let seconds = data.gameTime - (minutes * 60)
        if (minutes < 10) minutes = "0" + minutes
        if (seconds < 10) seconds = "0" + seconds
        this.result.gameTime = minutes + ':' + Math.floor(Math.floor(seconds))

        if(this.result.win){
          this.$sfxPlay('winnerscreen')
        } else if(typeof this.result.win != undefined) {
          this.$sfxPlay('loserscreen')
        } else {
          this.$sfxPlay('tiescreen')
        }
        
      } else {
        this.$sfxPlay('finalscreen')
      }
      
    },
  },
};
</script>

<style src="./ExternalExperience.scss" lang="scss" scoped></style>